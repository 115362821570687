/** @namespace EroswholesaleScandipwa/Util/Common/String/stripHtml */
export const stripHtml = (string) => string?.replace(/(<([^>]+)>)/gi, '');

/** @namespace EroswholesaleScandipwa/Util/Common/String/truncate */
export const truncate = (string, length) => string?.substring(0, length);

/** @namespace EroswholesaleScandipwa/Util/Common/String/stripNonNumericChars */
export const stripNonNumericChars = (string) => string?.replace(/[^0-9.]/g, '');

/** @namespace EroswholesaleScandipwa/Util/Common/String/stringToTitleCase */
export const stringToTitleCase = (string) => string?.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());

/** @namespace EroswholesaleScandipwa/Util/Common/String/convertStringToCamelCase */
export const convertStringToCamelCase = (string) => stringToTitleCase(string?.split('.')[0]?.replace(/[^A-Za-z0-9 ]/g, ' ').trim()).replace(/[^A-Za-z0-9]/g, '');
