import firebase from 'firebase/compat/app';

import { MyAccountDispatcher as SourceMyAccountDispatcher } from 'SourceStore/MyAccount/MyAccount.dispatcher';
import { updateCustomerSignInStatus, updateIsLoading } from 'Store/MyAccount/MyAccount.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { setAuthorizationToken } from 'Util/Auth';
import { getGuestQuoteId, setGuestQuoteId } from 'Util/Cart';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
    );

export const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Wishlist/Wishlist.dispatcher'
    );

export const BurgerMenuDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/BurgerMenu/BurgerMenu.dispatcher'
    );

export const ProductCompareDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/ProductCompare/ProductCompare.dispatcher'
    );

export * from 'SourceStore/MyAccount/MyAccount.dispatcher';

/** @namespace EroswholesaleScandipwa/Store/MyAccount/Dispatcher */
export class MyAccountDispatcher extends SourceMyAccountDispatcher {
    async signInWithFirebaseAuth(token, response, dispatch) {
        /**
         * with the exception of passing in the "options" variable which contains email and password,
         * this function should kept identical to the signIn function of the parent MyAccountDispatcher class
         * */
        setAuthorizationToken(token);

        /** We don't use product compare feature */
        /* ProductCompareDispatcher.then(
            ({ default: dispatcher }) => dispatcher.assignCompareList(dispatch)
        ); */

        const cartDispatcher = (await CartDispatcher).default;
        const guestCartToken = getGuestQuoteId();

        const burgerMenuDispatcher = (await BurgerMenuDispatcher).default;
        await this.requestCustomerData(dispatch);
        await burgerMenuDispatcher.closeAllMenus(dispatch);
        dispatch(hideActiveOverlay());
        dispatch(showNotification('success', __('You have successfully logged in!')));

        // if customer is authorized, `createEmptyCart` mutation returns customer cart token
        const customerCartToken = await cartDispatcher.createGuestEmptyCart(dispatch);

        if (guestCartToken && guestCartToken !== customerCartToken) {
            // merge guest cart id and customer cart id using magento capabilities
            await cartDispatcher.mergeCarts(guestCartToken, customerCartToken, dispatch);
        }

        setGuestQuoteId(customerCartToken);
        cartDispatcher.updateInitialCartData(dispatch, true);

        /** We don't use wishlist feature */
        /* WishlistDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch)
        ); */

        dispatch(updateCustomerSignInStatus(true));
        dispatch(updateIsLoading(false));
        BurgerMenuDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateMyAccountMenuState(false, dispatch)
        );

        return {
            result: true,
            response
        };
    }

    signIn(options = {}, dispatch) {
        const result = super.signIn(options, dispatch);
        result.then(
            /** @namespace EroswholesaleScandipwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/signIn/result/then */
            () => BurgerMenuDispatcher.then(
                        ({ default: dispatcher }) => dispatcher.updateMyAccountMenuState(false, dispatch)
                    )
            );

        return result;
    }

    setIsLoading(isLoading, dispatch) {
        dispatch(updateIsLoading(isLoading));
    }
}

export default new MyAccountDispatcher();
