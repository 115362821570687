/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import _ from 'lodash';

import OrderQuery from 'Query/Order.query';
import { CHECKOUT_URL, SHIPPING_URL } from 'Route/Checkout/Checkout.config';
import { isCheckout } from 'Util/Checkout/Checkout';
import history from 'Util/History';
import { prepareQuery } from 'Util/Query';
import { executeGet } from 'Util/Request';

import {
    fireAddPaymentInfoEvent,
    fireAddShippingInfoEvent,
    fireBeginCheckoutEvent,
    firePurchaseEvent,
    fireViewedShippingMethodsEvent
} from '../../event/checkout';

const routerContainerComponentDidMount = (args, callback) => {
    const result = callback(...args);

    const { location: { pathname } } = history;
    if (pathname === CHECKOUT_URL || pathname === SHIPPING_URL) {
        try {
            fireBeginCheckoutEvent();
        } catch (e) {
            console.error('An error occurred while trying to transmit GTM data.', e);
        }
    }

    history.listen((history) => {
        const { pathname } = history;
        if (pathname === CHECKOUT_URL) {
            try {
                fireBeginCheckoutEvent();
            } catch (e) {
                console.error('An error occurred while trying to transmit GTM data.', e);
            }
        }
    });

    return result;
};

const addFireAddShippingInfoEvent = (args, callback, instance) => callback(...args).finally(() => {
    try {
        if (isCheckout) {
            const { state: { shippingMethods = [] } } = instance;
            const { shipping_carrier_code, shipping_method_code } = _.head(args);
            const selectedShippingMethod = [shipping_carrier_code, shipping_method_code].join('_');

            fireAddShippingInfoEvent(shippingMethods, selectedShippingMethod);
        }
    } catch (e) {
        console.error('An error occurred while trying to transmit GTM data.', e);
    }
});

const addFireAddPaymentInfoEvent = (args, callback, instance) => callback(...args).finally(() => {
    try {
        const { paymentMethod: { code = null } = {} } = _.first(args);
        const { state: { paymentMethods = [] } } = instance;
        const paymentMethod = _.find(paymentMethods, ({ code: paymentMethodCode }) => code === paymentMethodCode);

        fireAddPaymentInfoEvent(paymentMethods, !_.isUndefined(paymentMethod) ? paymentMethod : null);
    } catch (e) {
        console.error('An error occurred while trying to transmit GTM data.', e);
    }
});

const addFirePurchaseEvent = async (args, callback, instance) => {
    try {
        const [orderId] = args;
        const { state: { email }, props: { isSignedIn, totals: { id: cartId } } } = instance;

        if (isSignedIn) {
            // eslint-disable-next-line max-len
            const { customerOrderByIncrementId: order } = await executeGet(prepareQuery(OrderQuery.getCustomerOrderByIncrementId(orderId)));
            firePurchaseEvent(order);
        } else {
            // eslint-disable-next-line max-len
            const { guestOrderByEmailCartIdIncrementId: order } = await executeGet(prepareQuery(OrderQuery.getGuestOrderByEmailCartIdIncrementId(email, cartId, orderId)));
            firePurchaseEvent(order);
        }
    } catch (e) {
        console.error('An error occurred while trying to transmit GTM data.', e);
    }

    /** This must be called after guest quote id is obtained */
    callback(...args);
};

export const addFireViewedShippingMethodsEvent = (args, callback, instance) => {
    const result = callback(...args);

    try {
        const { shippingMethods: prevShippingMethods = [] } = _.first(args);
        const { props: { shippingMethods = [] } = {} } = instance;

        const areShippingMethodsEqual = _.isEqual(prevShippingMethods, shippingMethods);
        if (!areShippingMethodsEqual) {
            fireViewedShippingMethodsEvent(shippingMethods);
        }
    } catch (e) {
        console.error('An error occurred while trying to transmit GTM data.', e);
    }

    return result;
};

export default {
    'Route/Checkout/Container': {
        'member-function': {
            saveAddressInformation: addFireAddShippingInfoEvent,
            saveBillingAddress: addFireAddPaymentInfoEvent,
            setDetailsStep: addFirePurchaseEvent
        }
    },
    'EroswholesaleScandipwa/Component/Router/Container': {
        'member-function': {
            componentDidMount: routerContainerComponentDidMount
        }
    },
    'Component/CheckoutDeliveryOptions/Component': {
        'member-function': {
            componentDidUpdate: addFireViewedShippingMethodsEvent
        }
    }
};
